body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.board--cell {
  width: 30px;
  height: 30px;
  border: 1px solid #999;
  float: left;
}

.board--row {
  clear:both;
}

.board--black {
  background-color: #000;
  color:#000;
}

.board--grey {
  background-color: darkgray;
  color: darkgray;
}

.board--white {
  background-color: white;
  color:white;
}


